/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import KeenSlider, { KeenSliderInstance } from "keen-slider";

const HOME_TOP_SLIDER = '[data-keen-slider="home"]';
const HOME_CATEGORY_SLIDER = '[data-keen-slider="category"]';
const HOME_OPINIONS_SLIDER = '[data-keen-slider="opinions"]';
const ABOUT_US_SLIDER = '[data-keen-slider="about-us"]';
const PRODUCT_MAIN_SLIDER = "[data-prod-slider]";
const PRODUCT_MAIN_SLIDER_THUMBS = "[data-prod-slider-thumbs]";
const SLIDER_TRACKING = "[data-keen-slider-tracking]";
const SLIDER_DOTS = "[data-keen-slider-dots] button";

const initSliderDots = (slider, $dots) => {
  $dots.forEach(($dot, idx) => {
    if (idx == 0) {
      $dot.classList.add("dot--active");
    }
    $dot.addEventListener("click", () => slider.moveToIdx(idx));
  });
};

const updateSliderDotsClasses = (currentSlideID, $dots) => {
  $dots.forEach(($dot, idx) => {
    $dot.classList.remove("dot--active");
    if (currentSlideID === idx) {
      $dot.classList.add("dot--active");
    }
  });
};

export const initTOPHomeSlider = () => {
  const $slider = document.querySelector<HTMLElement>(HOME_TOP_SLIDER);

  if ($slider) {
    const $sliderSlides = $slider.querySelector<HTMLElement>(SLIDER_TRACKING);
    if ($sliderSlides) {
      const sliderObject = new KeenSlider(
        $sliderSlides,
        {
          slides: {
            spacing: 0,
            perView: 1,
          },
          loop: true,
        },
        [
          (slider) => {
            let timeout;
            let mouseOver = false;
            function clearNextTimeout() {
              clearTimeout(timeout);
            }
            function nextTimeout() {
              clearTimeout(timeout);
              if (mouseOver) return;
              timeout = setTimeout(() => {
                slider.next();
              }, 8000);
            }
            slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
                mouseOver = true;
                clearNextTimeout();
              });
              slider.container.addEventListener("mouseout", () => {
                mouseOver = false;
                nextTimeout();
              });
              nextTimeout();
            });
            slider.on("dragStarted", clearNextTimeout);
            slider.on("animationEnded", nextTimeout);
            slider.on("updated", nextTimeout);
          },
        ]
      );

      const $dots = $slider.querySelectorAll<HTMLButtonElement>(SLIDER_DOTS);

      if ($dots.length) {
        sliderObject.on("slideChanged", (track) => {
          updateSliderDotsClasses(track.track.details.rel, $dots);
        });

        initSliderDots(sliderObject, $dots);
      }
    }
  }
};

export const initCategoriesSliders = () => {
  const $sliders = document.querySelectorAll<HTMLElement>(HOME_CATEGORY_SLIDER);
  $sliders.forEach(($singleCategorySlider) => {
    const $sliderSlides = $singleCategorySlider.querySelector<HTMLElement>(SLIDER_TRACKING);
    if ($sliderSlides) {
      const sliderObject = new KeenSlider($sliderSlides, {
        slides: {
          spacing: 22,
          perView: "auto",
        },
        loop: true,
      });

      const $dots = $singleCategorySlider.querySelectorAll<HTMLElement>(SLIDER_DOTS);

      if ($dots.length) {
        sliderObject.on("slideChanged", (track) => {
          updateSliderDotsClasses(track.track.details.rel, $dots);
        });

        initSliderDots(sliderObject, $dots);
      }
    }
  });
};

export const initOpinionsSliders = () => {
  const $slider = document.querySelector<HTMLElement>(HOME_OPINIONS_SLIDER);

  if ($slider) {
    const $sliderSlides = $slider.querySelector<HTMLElement>(SLIDER_TRACKING);

    if ($sliderSlides) {
      const sliderObject = new KeenSlider($sliderSlides, {
        slides: {
          spacing: 22,
          perView: 1,
        },
        loop: true,
        breakpoints: {
          "(min-width: 768px)": {
            slides: {
              spacing: 22,
              perView: 2,
            },
          },
        },
      });

      const $dots = $slider.querySelectorAll<HTMLButtonElement>(SLIDER_DOTS);

      if ($dots.length) {
        sliderObject.on("slideChanged", (track) => {
          updateSliderDotsClasses(track.track.details.rel, $dots);
        });

        initSliderDots(sliderObject, $dots);
      }
    }
  }
};

export const initProdSlider = () => {
  const ThumbnailPlugin = function (main: KeenSliderInstance) {
    return (slider: KeenSliderInstance) => {
      function removeActive() {
        slider.slides.forEach((slide) => slide.classList.remove("active--thumb"));
      }
      function addActive(idx: number) {
        slider.slides[idx].classList.add("active--thumb");
      }

      function addClickEvents() {
        slider.slides.forEach((slide, idx) => {
          slide.addEventListener("click", () => {
            main.moveToIdx(idx);
          });
        });
      }

      slider.on("created", () => {
        slider.container.classList.remove("product-thumbs-slider-preinit");
        addActive(slider.track.details.rel);
        addClickEvents();
        main.on("animationStarted", (main: KeenSliderInstance) => {
          removeActive();
          const next = main.animator.targetIdx || 0;
          addActive(main.track.absToRel(next));
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
        });
      });
    };
  };

  const $prodSlider = document.querySelector<HTMLElement>(PRODUCT_MAIN_SLIDER);
  const $prodSliderThumbs = document.querySelector<HTMLElement>(PRODUCT_MAIN_SLIDER_THUMBS);

  if ($prodSlider && $prodSliderThumbs) {
    const mainProduct = new KeenSlider(
      $prodSlider,
      {
        loop: false,
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
      []
    );

    const mainProductThumbs = new KeenSlider(
      $prodSliderThumbs,
      {
        mode: "free-snap",
        slides: {
          origin: "center",
          perView: 3,
          spacing: 20,
        },
      },
      [ThumbnailPlugin(mainProduct)]
    );

    const $buttonLeft = document.querySelector<HTMLButtonElement>("[data-prod-slider-left]");
    const $buttonRight = document.querySelector<HTMLButtonElement>("[data-prod-slider-right]");

    if ($buttonLeft) {
      $buttonLeft.addEventListener("click", () => mainProduct.prev());
    }
    if ($buttonRight) {
      $buttonRight.addEventListener("click", () => mainProduct.next());
    }
  }
};

export const aboutUsSlider = () => {
  const $slider = document.querySelector<HTMLElement>(ABOUT_US_SLIDER);

  if ($slider) {
    const $sliderSlides = $slider.querySelector<HTMLElement>(SLIDER_TRACKING);

    if ($sliderSlides) {
      const sliderObject = new KeenSlider($sliderSlides, {
        slides: {
          spacing: 22,
          perView: "auto",
        },
        loop: true,
      });

      const $dots = $slider.querySelectorAll<HTMLButtonElement>(SLIDER_DOTS);

      if ($dots.length) {
        sliderObject.on("slideChanged", (track) => {
          updateSliderDotsClasses(track.track.details.rel, $dots);
        });

        initSliderDots(sliderObject, $dots);
      }
    }
  }
};
