/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */

import { initTOPHomeSlider, initCategoriesSliders, initOpinionsSliders, initProdSlider, aboutUsSlider } from "./js/components/sliders";

initTOPHomeSlider();
initCategoriesSliders();
initOpinionsSliders();
initProdSlider();
aboutUsSlider();
